import React, { useEffect, useState } from 'react'
import * as styles from './quantityModal.module.scss'
import { Row, Col } from 'react-grid-system'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import { ToolStatus } from '../../types'

import { Button, Image, Modal } from '../common'
import { truncate } from '../../common/utils/functions'
import { QuantityModalProps } from './actionsWorkflow.type'

export const QuantityModal = ({
   isModalOpen,
   handleClose,
   handleAdjustTotal,
   handleCustomTotalChange,
   handleAddQuantityItemsToCart,
   getQuantityItemsFromPage,
   maxWidth,
   items,
   zIndex,
   isEdit,
   selectedActionQuickFilter,
}: QuantityModalProps) => {
   const [itemInitialQuantity, setItemInitialQuantity] = useState<
      number | null
   >(null)
   const [isQuantityValid, setIsQuantityValid] = useState<boolean>(true)
   const [title, setTitle] = useState<string>(`Select Quantities`)
   const [modalMessage, setModalMessage] = useState<string>(
      `How many of the following items should be added to your cart?`
   )

   const isQuantitiesDisabled =
      selectedActionQuickFilter?.status === ToolStatus.AVAILABLE &&
      selectedActionQuickFilter?.label === 'Warehouse Transfer'

   useEffect(() => {
      if (isEdit && !isQuantitiesDisabled) {
         setItemInitialQuantity(items[0].quantityForCart)
         setTitle(`Edit Quantities`)
         setModalMessage(`Update the quantity of this Item in your Cart.`)
      }
      if (isQuantitiesDisabled) {
         setModalMessage(
            `Quantity Items can't be split between Warehouses. Total quantities shown will be transferred`
         )
      }
   }, [])

   // validate quantity
   useEffect(() => {
      if (
         getQuantityItemsFromPage(items).some(
            (x) =>
               x.quantityForCart < 1 ||
               x.quantityForCart > x.StatusQuantity ||
               //    @ts-ignore
               x.quantityForCart === ''
         )
      ) {
         setIsQuantityValid(false)
      } else {
         setIsQuantityValid(true)
      }
   }, [items])

   const handleEditCancel = () => {
      items[0].quantityForCart = itemInitialQuantity // if in edit mode set item quantity back to last known state
      handleClose()
   }
   return (
      <Modal
         isModalVisible={isModalOpen}
         closeModal={handleClose}
         title={title}
         maxWidth={maxWidth}
         zIndex={zIndex}
      >
         <div className={styles.warningModalMessageWrapper}>
            <p>{modalMessage}</p>
         </div>

         <section className={styles.selectionWrapper}>
            {getQuantityItemsFromPage(items).map((item, i) => {
               const isValid =
                  item.quantityForCart > 0 &&
                  item.quantityForCart <= item.StatusQuantity &&
                  !isNaN(item.quantityForCart)

               return (
                  <div key={item.localUniqueId + '_' + i}>
                     <section className={styles.itemRow}>
                        <div
                           className={
                              !isQuantitiesDisabled
                                 ? styles.totalSelector
                                 : styles.totalSelectorDisabled
                           }
                        >
                           <div
                              className={styles.incDecButton}
                              onClick={() => handleAdjustTotal(item, 'dec')}
                           >
                              <AiOutlineMinusCircle size={25} />
                           </div>
                           <input
                              className={
                                 isValid
                                    ? styles.quantityInput
                                    : styles.quantityInputInvalid
                              }
                              type="number"
                              id="quantity"
                              name="quantity"
                              min="1"
                              max="10000"
                              value={
                                 !isQuantitiesDisabled
                                    ? item.quantityForCart
                                    : item?.StatusQuantity
                              }
                              onChange={(e) => handleCustomTotalChange(e, item)}
                              style={
                                 isQuantitiesDisabled
                                    ? {
                                         pointerEvents: 'none',
                                         background: '#F3F3F3',
                                         color: 'darkgrey',
                                      }
                                    : {}
                              }
                           ></input>
                           <div
                              className={styles.incDecButton}
                              onClick={() => handleAdjustTotal(item, 'inc')}
                           >
                              <AiOutlinePlusCircle size={25} />
                           </div>
                        </div>
                        <span className={styles.availableTotal}>
                           {`${item.StatusQuantity} Available`}
                        </span>
                        <span className={styles.quantityImage}>
                           <Image
                              url={item.PictureURL}
                              alt={item.PictureURL}
                              defaultImagePadding={2}
                              width={30}
                           />
                        </span>
                        <span className={styles.itemNameSection}>
                           {truncate(item.Title, 45)}
                        </span>
                     </section>
                     {!isValid && (
                        <p className={styles.invalidWarning}>
                           Invalid quantity
                        </p>
                     )}
                  </div>
               )
            })}
         </section>
         <div className={styles.buttonRow}>
            <Row>
               <Col xs={12} sm={6} md={6} lg={6}>
                  <div className={styles.buttonWrapper}>
                     <Button
                        variant={'primary'}
                        onClick={
                           isEdit
                              ? handleClose
                              : () => handleAddQuantityItemsToCart(items)
                        }
                        type="button"
                        isReset
                        disabled={!isQuantityValid}
                     >
                        {isEdit ? 'Update Cart' : 'Add To Cart'}
                     </Button>
                  </div>
               </Col>
               <Col xs={12} sm={6} md={6} lg={6}>
                  <div className={styles.buttonWrapper}>
                     <Button
                        variant={'tertiary'}
                        onClick={isEdit ? handleEditCancel : handleClose}
                        type="button"
                        isReset
                     >
                        Cancel
                     </Button>
                  </div>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}
