import React from 'react'

import { AddToCartButtonProps } from './actionsWorkflow.type'
import { Svg } from '../common'

export const AddToCartButton = ({
   action,
   cell,
   isKitItem,
   isItemInCart,
}: AddToCartButtonProps) =>
   isKitItem ? (
      <></>
   ) : (
      <button
         className={`icon-button icon-${'addToCart'}`}
         onClick={() => action(cell)}
         disabled={false}
         style={{
            width: '110%',
            height: '115%',
            marginLeft: '-5%',
            marginTop: '-5%',
            zIndex: 9,
         }}
         type="button"
         id="addToCart"
      >
         <Svg id={!isItemInCart ? 'addToCart' : 'removeFromCart'} />
      </button>
   )
