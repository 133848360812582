import React from 'react'
import { IconButton } from '../common'
import { Actions } from '../../constants/tables'
import { ActionButtonProps } from '../common/crud/types'

const CrudActionCartButtons = ({
   cell,
   action,
   editAction,
   deleteAction,
}: ActionButtonProps) => (
   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {editAction && (
         <IconButton
            id="edit"
            action={() => action(Actions.Edit, cell.value)}
         />
      )}
      {deleteAction && (
         <IconButton
            id="removeFromCart"
            action={() => action(Actions.Delete, cell.value)}
         />
      )}
   </div>
)

export default CrudActionCartButtons
