import React from 'react'
import Button from '../common/button/button'

import { CartButtonProps } from './actionsWorkflow.type'
import { Svg } from '../common'

const CartButton = ({ onClick, text, iconId, disabled }: CartButtonProps) => (
   <Button
      variant="plain"
      preserveText
      onClick={onClick}
      minWidth="auto"
      disabled={disabled}
   >
      {iconId && (
         <span>
            <Svg id={iconId} />
         </span>
      )}
      {text && <span>{text}</span>}
   </Button>
)

export default CartButton
