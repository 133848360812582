import React from 'react'
import Button from '../common/button/button'

import { AddAllToCartButtonProps } from './actionsWorkflow.type'
import { Svg } from '../common'

const AddAllToCartButton = ({ onClick, text }: AddAllToCartButtonProps) => (
   <Button variant="plain" preserveText onClick={onClick} minWidth="auto">
      <span>
         <Svg id="addToCart" />
      </span>
      {text && <span>{text}</span>}
   </Button>
)

export default AddAllToCartButton
